@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-horizon/src/bootstrap-horizon.css";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icons.min.css";

.order-button-holder{
    right: 2%;
    bottom: 50%;
    display: block;
    z-index: 99999;
    position: fixed;
    width: fit-content;
  }
  .order-button-takeaway{
    background-color: #fe319e;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
    margin: 10px;
  }
  .order-button-wolt{
    background-color: #009de0;
    color: white;
    border-radius: 50px;
    padding: 10px 20px;
    margin: 10px;
  }
  .order-button-holder a{
    text-decoration: none;
    color: white;
  }

  .table td.fit, 
.table th.fit {
   white-space: nowrap;
   width: 1%;
}

table.bg-table-transparent {
    background-color: transparent;
    --bs-table-bg: transparent;
}

@media (max-width: 575.98px) {
  .mh-md-3em {
    object-fit: cover; /* Do not scale the image */
    width: 4em;
    height: 4em;
    object-position: center; /* Center the image within the element */
  }
  .p-md--02em{
    padding: 0.2em !important;
  }
}

@media (min-width: 576px) {
  .mh-md-3em {
    max-width: 5em; /* Set max height to 6em on larger screens */
  }
  .p-md--02em{
    padding: 0.5em;
  }
}
.dropLinkContainer{
  max-width: 900px;
  overflow: auto;
  display: flex;
  white-space: nowrap;
  justify-content: safe center;
  align-items: center;
}

.dropLinkContainer a{
  text-decoration: none;
  color: black;
  background-color: #fe319e;
  border-width: 2px;
  border-color: black;
  margin: 0.5em;
}

.dropLinkContainer:first-child a{
  margin-left: auto;
}

.dropLinkContainer:last-child a{
  margin-right: auto;
}

.dropLinkContainer a:hover{
  background-color: #fe319e;
  border-color: black;

}
.dropLinkContainer a:active{
  background-color: #fe319e;
  border-color: black;
}